import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Image from "../components/image"
import "../assets/testimonials.sass"
import "../assets/wave.sass"
import Carousel from 'react-bootstrap/Carousel'

const TestimonialsBar = () => {

  return(
    <div className="statement testimonial-bar client-statement-left">        
        <SEO title="X Signature " />
        <Carousel>
            <Carousel.Item interval={9000}>
                <p>
                I called X on a recommendation from a friend of mine. 
                I tried doing it myself( not a good idea), then called Roto-Rooter to give an estimate. 
                They are outrageously priced. they came out to do the job. I could not believe it. He was polite.
                He took great care to not make a mess and was finished within 45 minutes. 
                He is very reasonably priced and would recommend him to all that need his service.
                </p>
                <p>Tony Abbattista</p>
            </Carousel.Item>
            <Carousel.Item interval={9000}>
                <p>
                I highly recommend X. They were friendly and courteous, 
                took great care to keep everything clean (they even put booties on the wheels of the machine), 
                and were thorough and efficient in cleaning out my kitchen drain pipe. We're so happy to have our kitchen sink back!
                </p>
                <p>Chris Mang</p>
            </Carousel.Item>
        </Carousel>
    </div>
    
  )
};

export default TestimonialsBar